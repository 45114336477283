//--------------------- Begin new mobile header ----------------//
.top-header {
    background-color: $color_primary_dark;
    color: #fff;
    font-size: 15px;
}

.top-header__inner {
    max-width: 1280px;
    display: grid;
    grid-template-columns: 1fr auto;
    margin: 0 auto;
}

.header-top__note {
    display: none;
}

.header-top__note--mobile {
    grid-row: 2;
    grid-column: 1;
    padding: 15px;
    text-align: left;
    align-self: center;

    .is--logged-in & {
        padding: 5px 10px;
    }
}

.shop-location {
    padding-left: 10px;
    padding-bottom: 10px;
    grid-row: 3;
    grid-column: 1;
    color: #fff;
}

.utility-bar {
    grid-row: 2 / span 2;
    grid-column: 2;
    background-color: $color_primary_dark;
    align-self: center;

    a {
        color: #fff;
    }
}

.utility-bar__inner {
    grid-template-columns: 1fr auto auto;
    max-width: 1000px;
}

.login-links {
    grid-column: 2;
    text-transform: uppercase;
}

.account-header {
    grid-column: 2;
    background-color: $color_primary_dark;
}

.account-header__icon {
    display: block;
    justify-self: right;
}

.account-header__toggle {
    display: block;
    justify-self: left;
}

.account-header__icon .icon-person .icon-circle {
    fill: #fff;
    stroke: #fff;
    stroke-width: 2;
}

.account-header__icon .icon-person .icon-body,
.account-header__icon .icon-person .icon-head,
.account-header__toggle {
    fill: $color_primary_dark;
}

.account-header__toggle svg,
.header-cart__icon svg {
    fill: #fff;
}

.account-header__nav {
    background-color: $color_primary_dark;
}

.header-cart {
    grid-column: 3;
}

.header-cart__value {
    color: $color_primary_dark;
}

.login-links,
.header-cart,
.header-top__countdown {
    margin-right: 8px;
}

.header-search {
    grid-column: 2;
    max-width: 400px;
    padding-left: 30px;
}

.search__options {
    display: none;
}

.search,
.search__text {
    max-width: unset;
}

.search__field {
    padding: 10px;
}

.search__submit {
    flex: 0 0 20px;
}

.button--search {
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    background-image: url("/content/themes/premierbeauty/images/search-icon.png");
}

.header-top__countdown {
    grid-row: 1;
    grid-column: 2;
    width: 100%;
    padding-top: 5px;
    padding-right: 10px;
}

.header-top--added {
    background-color: #338326;
}

.site-logo--mobile {
    display: none;
}

.header-banner {
    display: grid;
    align-items: center;
    max-width: 600px;
    font-size: 15px;
    padding: 10px;
    margin: 0 auto;
    grid-template-columns: auto 1fr;
}

.site-logo {
    padding: 0px;
}

.site-logo__img {
    max-width: 180px;
}

@media only screen and (min-width: 820px) {
    .title-wrap {
        padding-top: 5em;
        padding-bottom: 1em;
    }

    .is--logged-in .title-wrap {
        padding-top: 6em;
        padding-bottom: 0;
    }

    .account-header__nav {
        box-shadow: 3px 3px 8px rgba(0,0,0,.16);
    }
}

@media only screen and (min-width: 820px) and (min-height: 600px) {
    .fixed-header {
        position: sticky;
        top: 0;
        z-index: 1000;
        background-color: #fff;
    }
}

@media only screen and (max-width: 620px) {
    .top-header {
        background-color: #fff;
    }

    .top-header__inner {
        grid-template-columns: 1fr 1fr;
    }

    .header-top__note--mobile {
        width: 100%;
        grid-column: 1 / span 2;
        grid-row: 1;
        padding: 10px 5px;
        text-align: center;
        background-color: $color_primary_dark;
    }

    .header-top__assistance {
        display: none;
        grid-column: unset;
    }

    .header-top__assistance--mobile,
    .header-top__locator {
        grid-row: 2;
        display: flex;
        align-items: center;
        padding: 10px;
        background-color: #fff;
    }

    .header-top__locator {
        grid-column: 1;
    }

    .header-top__assistance--mobile {
        grid-column: 2;
    }

    .header-top__assistance-contact {
        display: none;
    }

    .shop-location {
        margin: 0 auto;
        font-size: 13px;
        grid-column: 1 / span 2;
        text-align: center;
        padding: 10px;
    }

    .shop-location__content {
        color: $color_primary_dark;
    }

    .shop-location__icon {
        display: none;
    }

    .header-top__countdown {
        grid-row: 5;
        grid-column: 1 / span 2;
        text-align: center;
        padding: 5px 0 10px;
        background-color: #fff;
        color: $color_primary_dark;
        margin: 0 auto;
    }

    .site-logo--mobile {
        grid-row: 6;
        padding: 10px;
        margin: 0 auto;
        display: block;
        grid-column: 1 / span 2;
        background-color: #fff;

        .is--logged-out & {
            padding-top: 20px;
        }
    }

    .utility-bar {
        grid-row: 7;
        grid-column: 1 / span 2;
        background-color: #fff;
        padding: 0px;
    }

    .utility-bar__inner {
        grid-template-columns: 80% 20%;
        justify-items: center;

        a {
            color: #000;
        }
    }

    .account-header {
        width: 100%;
        grid-column: 1;
        color: #000;
        background-color: #fff;
    }

    .header-cart {
        grid-column: 2;
        justify-self: end;
    }

    .account-header__account-greeting {
        display: none;
    }

    .account-header__header-i-guess {
        display: grid;
        grid-template-columns: auto auto 75%;
        align-items: center;
    }

    .account-header__item a {
        color: #fff;
    }

    .account-header__icon {
        grid-column: 1;
        grid-row: 1;

        .icon-person .icon-circle {
            stroke: $color_primary_dark;            
            stroke-width: 1;
        }
    }

    .account-header__toggle {
        grid-column: 2;
        grid-row: 1;
        padding: 5px 10px 0 5px;
    }

    .account-header__title {
        grid-column: 3;
        text-align: left;
    }

    .header-cart__icon svg,
    .account-header__toggle svg {
        fill: $color_primary_dark;
    }

    .header-cart__value {
        color: #fff;
    }

    .search {
        display: none;
    }

    .header-banner {
        display: none;
    }

    .title-wrap,
    .is--logged-in .title-wrap {
        padding: 0px;
    }

    .login-links {
        grid-column: 1;
        justify-self: start;
        padding-left: 20px;
        color: #58595B;

        span {
            color: #58595B;
        }
    }

    .header-search {
        padding-right: 10px;
        padding-left: 0;
        width: 100%;
        grid-column: 2;
        max-width: unset;
    }
}
//---------------------- End new mobile header -----------------//
