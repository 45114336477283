﻿// -- menu
.menu-wrap {
    border-bottom: 1px solid #acacac;
}

.site-nav {
    padding-top: 0;
    background-color: transparent;
}

.site-nav__inner {
    padding-top: 2em;
    background-color: hsla(0, 0, 94.9%, 0.97);
}

.site-nav__item {
    border-bottom: none;
}

.site-nav__top {
    padding: 10px;
    color: $text_dark;
    font-size: 18px;
    font-weight: normal;
    background-color: transparent;
}

.site-nav__list {
    border-top: none;
}

.sub-nav {
    font-size: 18px;

    li {
        border-bottom: none;
    }

    a {
        padding: 10px;
        background-color: transparent;
    }
}

.sub-nav__back {
    font-weight: normal;
    border-bottom: none;
}

.sub-nav__highlight {
    color: $color_link_highlight;
}

@media only screen and (min-width: 820px) {
    .site-nav {
        max-width: 1200px;
    }

    .site-nav__inner {
        padding-top: 0;
        background-color: transparent;
    }

    .site-nav__top {
        display: inline-flex;
        flex-direction: column;
        padding: 10px;

        /*&::after {
            content: attr(data-title);
            content: attr(data-title) / "";
            height: 0;
            visibility: hidden;
            overflow: hidden;
            user-select: none;
            pointer-events: none;
            font-style: italic;
            font-weight: 500;

            @media speech {
                display: none;
            }
        }*/
    }

    .site-nav__item:hover {
        .site-nav__top {            
            font-weight: 500;
            background-color: #f4efe6;
        }
    }

    .site-nav__item--holiday .site-nav__top {
        color: #ffffff;
        background-color: #b1a068;
    }

    .site-nav__item--holiday:hover .site-nav__top {
        background-color: #f4efe6;        
        font-weight: 500;
    }

    .sub-nav {
        font-size: 14px;
        background-color: $color_white;
        border-radius: 0;
        box-shadow: $box_shadow;

        a {
            padding: 5px;
            text-transform: uppercase;
        }
    }

    .sub-nav__inner {
        padding: 0;
        background-color: transparent;
    }

    .sub-nav__col {
        width: 175px;

        .site-nav__item--wide & {
            width: 100%;
        }
    }
}

//------- global mobile changes ------//

.menu-wrap .search {
    display: none;
}

.nav-toggles,
.nav-toggle {
    background-color: #fff;
    justify-self: center;
}

.burger {
    width: 30px;
    height: 23px;
}

.burger__middle {
    background: $color_primary_dark;
    top: 10px;
}

.burger::before,
.burger::after {
    background: $color_primary_dark;
}

@media only screen and (max-width: 620px) {
    .menu-wrap {
        border: 0px;
        display: grid;
        grid-template-columns: 1fr 4fr;
        padding: 10px 0;

        .search {
            display: flex;
        }
    }
}