$medium_width: "720px";
$large_width: "840px";
$full_width: "1000px";

// -- base styles
.beyond-beauty {
    .beyond-beauty__h2 {
        margin: 0 0 20px;
        color: #A9A19B;
        font-size: 35px;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase;
    }

    .beyond-beauty__h3 {
        margin: 0 0 10px;
        color: #1d355d;
        font-size: 28px;
        font-weight: bold;
        text-align: left;
        text-transform: uppercase;
    }

    .beyond-beauty__events,
    .beyond-beauty__p {
        text-align: left;
        font-size: 25px;
    }

    .beyond-beauty__events {
        margin: 10px 0;
    }

    .beyond-beauty-qualifiers__note.notes {
        font-size: 17px;
        text-align: left;
    }

    li {
        text-align: left;
    }

    .beyond-beauty__p {
        font-weight: 600;
        margin-bottom: 0px;
    }

    .beyond-beauty__ss {
        display: flex;
        justify-content: center;
        padding-bottom: 50px;

        img {
            width: 600px;
        }
    }

    .bb-box__img {
        display: flex;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
    }

    ul {
        margin: 0 !important;
    }
}

@media only screen and (max-width: 680px) {
  .beyond-beauty {
    .beyond-beauty__p {
      padding-bottom: 20px;
    }
  }
}

.beyond-beauty__note {
    .beyond-beauty__h2 & {
        display: block;
        margin-top: 5px;
        color: #4c4c4c;
        font-size: 18px;
        font-weight: normal;
    }
}

@media only screen and (min-width: $large_width) {
  .beyond-beauty__note {
    .beyond-beauty__h2 & {
      font-size: 16px;
    }
  }
}

.beyond-beauty__button {
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  background-color: #988F87;
  border-radius: 4px;
}

@media only screen and (min-width: $large_width) {
  .beyond-beauty__button {
    font-size: 18px;
  }
}

.beyond-beauty__button.top {
    background-color: #293847;
    padding: 10px 130px;
}

@media only screen and (max-width: 840px) {
    .beyond-beauty__button.top {
        padding: 10px 105px;
    }
}

// -- header
.beyond-beauty-header {
  .beyond-beauty-header__title {
    margin-bottom: 0;
  }
}

.beyond-beauty-header__links {
  text-align: center;
  margin-top: 60px;
}

.beyond-beauty-header__link {
  margin: 15px 0;
}

// -- summary
.beyond-beauty-summary {
    max-width: 850px;
    margin: 60px auto;
    padding: 0 20px;
    text-align: center;

    .beyond-beauty-summary__text {
        padding: 0;
        margin: 0;
        font-size: 18px;
    }

    .beyond-beauty-summary__lfg {
        padding: 0;
        margin: 0;
        color: #1d355d;
        font-size: 24px;
        font-weight: bold;
    }
}

@media only screen and (min-width: $large_width) {
  .beyond-beauty-summary {
    .beyond-beauty-summary__text {
      font-size: 20px;
      color: #293847;
    }

    .beyond-beauty-summary__lfg {
      font-size: 36px;
    }
  }
}

@media only screen and (min-width: $full_width) {
  .beyond-beauty-summary {
    padding-right: 0;
    padding-left: 0;
  }
}

// -- qualifiers
.beyond-beauty-qualifiers {
  padding: 0 20px;
  font-size: 18px;
  text-align: center;

  .beyond-beauty-qualifiers__list {
    max-width: 600px;
    margin: 30px auto !important;
    font-size: 18px;
    text-align: left;
  }
}

.beyond-beauty-qualifiers__note {
  display: block;
  color: #78879f;
  font-size: 16px;
  font-style: italic;
}

@media only screen and (min-width: $large_width) {
  .beyond-beauty-qualifiers {
    font-size: 20px;
    color:#293847;

    .beyond-beauty-qualifiers__list {
      font-size: 20px;
      color:#293847;
    }
  }

  .beyond-beauty-qualifiers__note {
    font-size: 20px;
  }
}

@media only screen and (min-width: $full_width) {
  .beyond-beauty-qualifiers {
    padding-right: 0;
    padding-left: 0;
  }
}

// -- tiers
.beyond-beauty-tier {
    position: relative;
    margin: 60px 0;
    padding: 40px 30px 20px;
    text-align: left;
    text-transform: uppercase;
    border: 2px solid #B6BABE;

    .beyond-beauty-tier__name {
        position: absolute;
        top: 0;
        left: 15px;
        margin-top: -3px;
        background-color: #fff;
        transform: translateY(-50%);
    }

    .beyond-beauty-tier__description {
        margin: 0;
        padding: 0;
        font-weight: bold;
    }

    .beyond-beauty-tier__benefits {
        margin: 0;
    }

    li {
        margin: 0;
        line-height: 1.25;
    }
}

@media only screen and (min-width: $large_width) {
  .beyond-beauty-tier {
    margin: 60px 90px;
    padding: 40px 75px 20px;

    .beyond-beauty-tier__name {
      left: 75px;
    }
  }
}

@media only screen and (min-width: 840px) {
    .beyond-beauty-tier {
        margin: 60px 90px;
        padding: 40px 60px 20px;
    }
}

// -- action
.beyond-beauty-action {
  margin: 60px 20px;
  text-align: center;
}

@media only screen and (min-width: $full_width) {
  .beyond-beauty-action {
    margin: 60px 0;
  }
}