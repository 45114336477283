.instashop-summary {
	font-family: $font_primary;
	font-size: 18px;
	text-align: center;

	h1 {
		font-size: 36px;
		font-weight: 300;

		@media screen and (min-width: 820px) {
			font-size: 48px;
		}
	}
}
