﻿.promo-header {
    background-color: $color_secondary;
}

.product-list__header-link {
    color: $color_primary;
}

.promo-assistance {
    .g-6,
    .g-6:first-of-type {
        border-color: $color_secondary;
    }
}

@media only screen and (min-width: 680.02px) { 
    .promo-resources__block {
        padding-left: 10px;
    }
}