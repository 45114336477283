.button {
	color: $color_white;
	background-color: $color_primary;
	border-radius: 0;

	&:hover {
		background-color: $color_primary_dark;
	}
}

.button--line, .button--line:hover {
	color: $color_dark_gray;
	background-color: $color_white;
}

.button--text {
	color: $color_link;
	/*background-color: transparent;*/
	border: none;

	&:hover {
		color: $color_link;
		/*background-color: transparent;*/
		text-decoration: underline;
	}
}

.button--quickview {
	background-color: $color_highlight;

	&:hover {
		background-color: darken($color_highlight, 10%);
	}
}

.button--pending {
	&::after {
		background-color: $color_primary;
	}
}

.RadGrid_Silk .rgEditForm .button {
	color: $color_white;
}
