.pb-header-h2,
.catalogs__header {
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  text-transform: uppercase;
}

@media only screen and (min-width: 640px) {
  .pb-header-h2,
  .catalogs__header {
    font-size: 48px;
  }
}

.pb-home-link {
  color: #000;
  font-size: 1.25em;
  font-style: italic;
  font-weight: 500;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  transition: color 0.3s ease-in-out;

  &::after {
    content: " >";
  }

  &:hover {
    color: $color_highlight;
  }
}

.pb-home-link--button {
  padding: 0;
  line-height: 1;
  transform: translateY(0.05em);
  cursor: pointer;
}

.button--clear {
  display: inline;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: 1;
  background-color: transparent;
  border: none;
}

.page--default .main-content {
  max-width: none;
}

// -- banners
.bx-wrapper .bx-pager.bx-default-pager {
  a {
    background-color: #707070bf;

    &.active {
      background-color: $color_highlight;
    }

    &:hover {
      background-color: lighten($color_highlight, 20%);
    }
  }
}

.slider--featured {
  .bx-wrapper .bx-pager {
    bottom: -10px;
  }
}

.bx-wrapper .bx-controls-direction a {
  width: 35px;
  height: 35px;
  background-image: url("/content/themes/premierbeauty/images/universal-nav-arrows.png");
  background-repeat: no-repeat;
  z-index: 900;
}

.bx-wrapper .bx-next,
.bx-wrapper .bx-next:hover {
  background-position: 100% 0;
}

.bx-wrapper .bx-prev,
.bx-wrapper .bx-prev:hover {
  background-position: 0 0;
}

.home-wrap .main-banner-wrap {
  margin: 0 auto;
}

.main-banner-wrap .bx-wrapper .bx-next {
  right: 20px;
  background-position: 100% 0;
}

.main-banner-wrap .bx-wrapper .bx-prev {
  left: 20px;
  background-position: 0 0;
}

.main-banner-wrap .bx-controls-direction {
  display: block;
}


// -- promo buckets
.promo-bucket__text {
    font-size: 12px;
    font-weight: 700;
    /*font-family: $font_primary_bold;*/
    text-align: center;
    color: #22345a;
}

.pb-bl {
  text-align: center;
}

.promo-buckets {
  padding-top: 20px;  
}

.promo-buckets__inner {
  display: grid;
  grid-template-columns: 1fr 1px 1fr;  
  max-width: 1000px;
  margin: 0 auto;
}

.promo-buckets__divider {
  height: calc(100% - 70px);
  margin: 35px 0;
  border-right: 2px solid black;
}

.promo-bucket {
  max-width: 500px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
}

.promo-bucket__image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto 10px;
}

.promo-bucket__link {
  color: $color_primary;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  text-transform: uppercase;
  transition: color 0.3s ease-in-out;

  &::after {
    content: " >";
  }

  &:hover {
    color: $color_highlight;
    text-decoration: none;
  }
}

.is--logged-out {
  .promo-bucket__link--logged-in {
    display: none;
  }

  .promo-bucket__link--logged-out {
    display: inline-block;
  }
}

.is--logged-in {
  .promo-bucket__link--logged-in {
    display: inline-block;
  }

  .promo-bucket__link--logged-out {
    display: none;
  }
}

.promo-bucket__inner img {
    height: 110px;    
}

.pb-bl {
  margin-top: 10px;
}

.pb-ssr {
  margin: 0 auto;
  max-width: 1000px;

  img {
    width: 100%;
  }
}

.logged-in-box-item {
  display: none;
}

.is--logged-in {
  .activation-box-item {
    display: none;
  }
  .logged-in-box-item {
    display: block;
  }
}

@media only screen and (min-width: 820px) {
    .promo-bucket__inner {
        display: grid;
        grid-template-columns: 1fr 325px;
        grid-gap: 10px;

        img {
            height: 150px;
        }
    }
  .promo-bucket__text {
    font-size: 30px;
    text-align: left;
  }

  .pb-bl {
    margin-top: 20px;
    text-align: left;
  }

  .promo-bucket {
    text-align: left;
  }

  .promo-bucket__image {
    margin-bottom: 15px;
  }

  .promo-bucket__link {
    font-size: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .main-banner-wrap .bx-wrapper .bx-next,
  .main-banner-wrap .bx-wrapper .bx-prev {
    display: none;
  }
}
// -- matcher buckets
.matcher-buckets {
  background-color: $color_light_gray;
}

.matcher-buckets__inner {
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px 0;
}

.matcher-buckets__divider {
  height: 100%;
  background-color: #00000029;
}

.matcher-bucket {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
  align-items: center;
  padding: 10px 0;
  font-size: 18px;
  line-height: 1.35;

  p {
    margin: 0 0 15px;
    padding: 0;
  }
}

.matcher-bucket__image {
  display: none;
}

.matcher-bucket__text {
  order: 2;
  padding: 0 15px;
}

.matcher-bucket__icon {
  order: 1;
}

.matcher-bucket__description {
  display: none;
}

.matcher-bucket__title {
  margin: 0;
  padding: 0;
  font-size: 32px;
  font-weight: 300;
  text-transform: uppercase;
}

.matcher-bucket__graphic {
  display: block;
  width: 100%;
  height: auto;
  max-width: 175px;
  min-width: 75px;
  margin: 0 auto;
}

@media only screen and (min-width: 820px) {
  .matcher-buckets__inner {
    margin: 60px auto;
    padding-top: 0;
    padding-bottom: 0;
  }

  .matcher-bucket {
    grid-template-columns: auto 3fr 1fr;
    padding: 0;
  }

  .matcher-bucket__title {
    font-size: 42px;
  }

  .matcher-bucket__description {
    display: block;
  }

  .matcher-bucket__image {
    display: block;
    order: 1;
  }

  .matcher-bucket__text {
    order: 2;
    padding: 0 20px;
  }

  .matcher-bucket__icon {
    order: 3;
  }

  .matcher-bucket__graphic {
    max-width: 100%;
    min-width: unset;
  }
}

// -- media
.home-media {
  max-width: 1000px;
  margin: 60px auto;
  font-size: 18px;
  text-align: center;
}

.home-media__link {
  display: inline-block;
  margin-top: 15px;
}

// -- instagram
.home-instagram {
    padding: 30px 0;
    font-size: 18px;
    text-align: center;
    background-color: $color_light_gray;
}

.home-instagram__inner {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 30px;
}

.home-instagram__title {
  margin-bottom: 30px;
}

@media only screen and (min-width: 1000px) {
  .home-instagram__inner {
    padding: 0;
  }
}

// -- articles
.header--home,
.bar {
  margin: 0 0 15px;
  padding: 0;
  color: inherit;
  font-size: 32px;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
  background-color: transparent;
  overflow: hidden;
}

@media only screen and (min-width: 820px) {
  .header--home,
  .bar {
    font-size: 48px;
  }
}

.blog-scroller {
  max-width: 1200px;
  margin: 60px auto;
}

.home-article__title {
  padding: 0 10px;
  font-size: 1.5em;
  font-weight: 500;
  text-align: center;
}

.home-article__teaser {
  display: none;
}

.home-article__text .home-article__link {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    background-color: transparent;
    pointer-events: none;
    transition: background-color 0.3s ease-in-out;
  }

  &:hover::after {
    background-color: $color_white;
  }

  &:hover .home-article__link--read {
    opacity: 1;
  }
}

.home-article__link--read {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 200;
  margin: auto;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  text-decoration: underline;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &::after {
    content: " >";
  }
}

// -- standard banner
.standard-banner {
  max-width: 1000px;
  margin: 60px auto;
}

// -- whats new
.slider-outer {
  margin: 60px 0 0;
}

.slider-caption {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}

.catalogs {
  max-width: 1000px;
  margin: 60px auto;
  border-top: none;
}

.catalogs__header {
  margin-bottom: 0.5em;
  text-align: center;
  border-bottom: none;
}

.catalog__text {
  font-weight: 600;
}

.catalog__text::after {
  width: 24px;
  height: 24px;
  margin: 0 0 0 2px;
  background-image: url("/content/themes/premierbeauty/images/home/banner-arrows-blue.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  transform: rotate(180deg) translateY(-25%);
}

.map-section {
  max-width: 1000px;
  margin: 60px auto;
}

.map-txt-inner {
  border-top: 2px solid #b5a468;
  border-bottom: 2px solid #b5a468;
  border-left: 2px solid #b5a468;
  margin-top: 40px;
}

.txt {
  margin-top: 25px;
  margin-bottom: 25px;
}

.tx-mg {
  margin-left: 30px;
}

.att-con {
  margin-top: 20px;
}

.abp-map-title {
  font-size: 2.6em;
  font-weight: 400;
  margin-bottom: 10px;
  color: #282828;
}

.abp-map-tx {
  font-size: 1.2em;
  color: #282828;
}

.abp-lm {
  font-weight: 600;
  text-decoration: underline;
  color: #282828;
}

.abp-atten {
    font-weight: 700;
    /*font-family: $font_primary_bold;*/
    font-size: 1.6em;
    color: #000;
    margin: 0;
}

.abp-times {
  font-size: 1.2em;
  color: #282828;
}

@media (max-width: 1199.98px) {
  .map-img {
    max-width: 100%;
  }
}
