// -- footer
.site-footer {
	max-width: none;
	padding: 0;
}

.footer-stripe {
	padding: 30px 10px 10px;
	color: $color_white;
	background-color: $color_primary;
}

.footer-inner {
	max-width: 1000px;
	margin: 0 auto;
}

.footer-row {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
}


// -- social
.footer-social {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.footer-social__link {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 28px;
	height: 28px;
	margin: 0 10px;
	text-align: center;
	background-color: $color_white;
	border-radius: 100%;
	transition: background-color 0.3s ease-in-out;

	&:hover {
		background-color: $color_highlight;
	}
}

.social-icon {
	display: block;
	width: 24px;
	height: 24px;

	path {
		fill: $color_primary;
	}
}

.social-icon--instagram {
	width: 20px;
	height: 20px;
	transform: translate(1px, 1px);

	svg {
		width: 100%;
		height: 100%;
	}

	path {
		fill: none;
	}
}

.social-icon--twitter {
	width: 22px;
	height: 22px;
}

// -- logo
.footer-logo {
	max-width: 40px;
	margin-right: 30px;
}

.footer-logo__image {
	display: block;
	max-width: 100%;
	height: auto;
}


// -- contact
.footer-contact {
	margin: 15px 0;
	padding: 0 30px;
}

.footer-contact__info {
	text-transform: uppercase;
}

.footer-contact__link {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	padding: 5px;
	color: inherit;
	text-decoration: none;

	&:hover {
		text-decoration: none;

		.contact-icon path {
			fill: $color_highlight;
		}
	}
}

.footer-contact__link-text {
	display: block;
}

.contact-icon {
	display: block;
	width: 24px;
	height: auto;
	margin: 0 15px 0 0;

	path {
		fill: $color_white;
		transition: fill 0.3s ease-in-out;
	}
}

@media only screen and (min-width: 640px) {
	.footer-contact {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		align-items: flex-end;
		margin-bottom: 0;
		padding: 0;
	}

	.footer-contact__info {
		text-align: center;
	}

	.footer-contact__link {
		display: block;
		padding: 10px 15px;
	}

	.contact-icon {
		margin: 0 auto 5px;
	}
}


// -- links
.footer-links {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	column-gap: 15px;
	margin-bottom: 45px;
	padding-top: 20px;
	padding-right: 15px;
	padding-left: 15px;
}

.footer-links__section {
	flex: 1 1 45%;
	margin-bottom: 30px;
}

.footer-links__header {
	margin-bottom: 0.5em;
	font-weight: 500;
	text-transform: uppercase;
}

.footer-links__list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.footer-links__item {
	margin: 8px 0;
}

.footer-links__link {
	color: inherit;
	font-size: 16px;
}

@media only screen and (min-width: 640px) {
	.footer-links {
		justify-content: space-between;
		padding-right: 60px;
		padding-left: 60px;
		border-top: 2px solid $color_white;
	}

	.footer-links__section {
		flex: unset;
		margin-bottom: 0;
	}
}


// -- copyright
.footer-copyright {
	padding-bottom: 15px;
	text-align: center;
}

.footer-copyright__text {
	font-size: 1em;
	font-style: italic;

	a {
		color: inherit;
	}
}

