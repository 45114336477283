.filters__header {
	font-size: 0.9em;
	background-color: $color_primary;
}

.pagination__link--active, .pagination__link--active:hover {
	color: $color_white;
	background: $color_primary;
}

.product-list-inner {
	margin-bottom: 30px;
}

.product-list {
	margin-top: 15px;
}

.price__label--highlight {
	background-color: #a2925b;
}

.button--notify {
	color: #fff;
	text-decoration: none;
}

.button--notify, .button--notify:hover {
	color: #fff;
}

.deal-sku__link {
	color: #4e748b;
}
