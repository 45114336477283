﻿.pb-glp {
  margin-top: 30px;
}
.hiw-title {
    font-weight: bold;
    /*font-family: $font_primary_bold;*/
    font-size: 2.5em !important;
    text-align: center;
    color: #22345a;
    padding: 30px 0 !important;
}
.hiw-text {
  font-size: 1.5em;
  color: #202020;
}
.bxs-btn {
  text-align: center;
  background: #22345a;
  width: 100%;
  height: 80px;
}
.bxs-text {
  font-weight: 600;
  border-radius: 0px !important;
  background-color: #22345a !important;
}
.pb-oosCon {
  border-top: 2px solid #dfdfdf;
}
.oos-title {
    font-weight: bold;
    /*font-family: $font_primary_bold;*/
    font-size: 2.5em !important;
    text-align: center;
    color: #22345a;
    padding: 30px 0;
}
.oos-img {
  padding: 30px;
}
.pb-bxs {
  margin: 50px 0;
}
.pb-oos {
  background-color: #22345a;
}
.oos-imgText {
  color: #fff !important;
  font-size: 1.5em;
  color: #202020;
  padding: 0 30px 30px !important;
}

.q-container {
  position: relative;
  top: 130px;
}
.q-title {
    font-weight: bold;
    /*font-family: $font_primary_bold;*/
    font-size: 2.5em !important;
    text-align: center;
    color: #fff;
}
.q-contact {
  padding-top: 30px;
  color: #fff;
  font-size: 1.5em;
  text-align: center;
}

@media (min-width: 1000px) {
  .pb-questions {
    margin-right: calc(-100vw / 2 + 1020px / 2);
    margin-left: calc(-100vw / 2 + 1020px / 2);
    height: 350px;
    background-color: #576d87;
    text-align: center;
    margin-top: 80px;
  }
}
@media (max-width: 999px) {
  .pb-questions {
    height: 350px;
    background-color: #576d87;
    text-align: center;
  }
}

@media (max-width: 819px) {
  .pb-glp {
    margin-top: 15px !important;
  }
  .hiw-title {
    padding: 15px 0 !important;
  }
  .hiw-text {
    font-size: 1.2em;
    color: #202020;
    margin: 0 20px !important;
  }
  .bxstwo {
    margin-top: 30px;
  }
  .shopN {
    text-align: center;
  }
  .button.shop-btn {
    width: 90%;
  }
  .oos-title {
    font-size: 1.9em !important;
  }
  .oos-imgText {
    font-size: 1.3em;
  }
}
