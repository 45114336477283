﻿.myaccount-nav__link {
    background-color: $color_primary;

    &--selected,
    &:hover {
        background-color: $color_highlight;
    }
}

.dashboard-buckets img {
    max-width: 100%;
    height: auto;
}