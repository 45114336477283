/*@import "./styles/fonts";*/
body {
  font-family: "futura-pt", "Helvetica", "Arial", sans-serif;
}

img {
  max-width: 100%;
  height: auto;
}

h1, h1 a {
  color: #22345a;
}

a {
  color: #c1b17e;
}

.main-content {
  max-width: none;
}

.main-content__inner > div {
  max-width: 1000px;
  margin: 0 auto;
}

.has--pencil .pencil,
.has--pencil .pencil-multi {
  max-width: none;
}

.pencil-banner {
  max-width: none;
}

.page--default .main-content__inner > div,
.page--explore-deals-default .main-content__inner > div {
  max-width: none;
}

.landing__header {
  display: none;
}

.button {
  color: white;
  background-color: #22345a;
  border-radius: 0;
}

.button:hover {
  background-color: #1d355d;
}

.button--line, .button--line:hover {
  color: #707070;
  background-color: white;
}

.button--text {
  color: white;
  /*background-color: transparent;*/
  border: none;
}

.button--text:hover {
  color: white;
  /*background-color: transparent;*/
  text-decoration: underline;
}

.button--quickview {
  background-color: #c1b17e;
}

.button--quickview:hover {
  background-color: #b09c5b;
}

.button--pending::after {
  background-color: #22345a;
}

.RadGrid_Silk .rgEditForm .button {
  color: white;
}

.top-header {
  background-color: #1d355d;
  color: #fff;
  font-size: 15px;
}

.top-header__inner {
  max-width: 1280px;
  display: grid;
  grid-template-columns: 1fr auto;
  margin: 0 auto;
}

.header-top__note {
  display: none;
}

.header-top__note--mobile {
  grid-row: 2;
  grid-column: 1;
  padding: 15px;
  text-align: left;
  align-self: center;
}

.is--logged-in .header-top__note--mobile {
  padding: 5px 10px;
}

.shop-location {
  padding-left: 10px;
  padding-bottom: 10px;
  grid-row: 3;
  grid-column: 1;
  color: #fff;
}

.utility-bar {
  grid-row: 2 / span 2;
  grid-column: 2;
  background-color: #1d355d;
  align-self: center;
}

.utility-bar a {
  color: #fff;
}

.utility-bar__inner {
  grid-template-columns: 1fr auto auto;
  max-width: 1000px;
}

.login-links {
  grid-column: 2;
  text-transform: uppercase;
}

.account-header {
  grid-column: 2;
  background-color: #1d355d;
}

.account-header__icon {
  display: block;
  justify-self: right;
}

.account-header__toggle {
  display: block;
  justify-self: left;
}

.account-header__icon .icon-person .icon-circle {
  fill: #fff;
  stroke: #fff;
  stroke-width: 2;
}

.account-header__icon .icon-person .icon-body,
.account-header__icon .icon-person .icon-head,
.account-header__toggle {
  fill: #1d355d;
}

.account-header__toggle svg,
.header-cart__icon svg {
  fill: #fff;
}

.account-header__nav {
  background-color: #1d355d;
}

.header-cart {
  grid-column: 3;
}

.header-cart__value {
  color: #1d355d;
}

.login-links,
.header-cart,
.header-top__countdown {
  margin-right: 8px;
}

.header-search {
  grid-column: 2;
  max-width: 400px;
  padding-left: 30px;
}

.search__options {
  display: none;
}

.search,
.search__text {
  max-width: unset;
}

.search__field {
  padding: 10px;
}

.search__submit {
  flex: 0 0 20px;
}

.button--search {
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  background-image: url("/content/themes/premierbeauty/images/search-icon.png");
}

.header-top__countdown {
  grid-row: 1;
  grid-column: 2;
  width: 100%;
  padding-top: 5px;
  padding-right: 10px;
}

.header-top--added {
  background-color: #338326;
}

.site-logo--mobile {
  display: none;
}

.header-banner {
  display: grid;
  align-items: center;
  max-width: 600px;
  font-size: 15px;
  padding: 10px;
  margin: 0 auto;
  grid-template-columns: auto 1fr;
}

.site-logo {
  padding: 0px;
}

.site-logo__img {
  max-width: 180px;
}

@media only screen and (min-width: 820px) {
  .title-wrap {
    padding-top: 5em;
    padding-bottom: 1em;
  }
  .is--logged-in .title-wrap {
    padding-top: 6em;
    padding-bottom: 0;
  }
  .account-header__nav {
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.16);
  }
}

@media only screen and (min-width: 820px) and (min-height: 600px) {
  .fixed-header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #fff;
  }
}

@media only screen and (max-width: 620px) {
  .top-header {
    background-color: #fff;
  }
  .top-header__inner {
    grid-template-columns: 1fr 1fr;
  }
  .header-top__note--mobile {
    width: 100%;
    grid-column: 1 / span 2;
    grid-row: 1;
    padding: 10px 5px;
    text-align: center;
    background-color: #1d355d;
  }
  .header-top__assistance {
    display: none;
    grid-column: unset;
  }
  .header-top__assistance--mobile,
  .header-top__locator {
    grid-row: 2;
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #fff;
  }
  .header-top__locator {
    grid-column: 1;
  }
  .header-top__assistance--mobile {
    grid-column: 2;
  }
  .header-top__assistance-contact {
    display: none;
  }
  .shop-location {
    margin: 0 auto;
    font-size: 13px;
    grid-column: 1 / span 2;
    text-align: center;
    padding: 10px;
  }
  .shop-location__content {
    color: #1d355d;
  }
  .shop-location__icon {
    display: none;
  }
  .header-top__countdown {
    grid-row: 5;
    grid-column: 1 / span 2;
    text-align: center;
    padding: 5px 0 10px;
    background-color: #fff;
    color: #1d355d;
    margin: 0 auto;
  }
  .site-logo--mobile {
    grid-row: 6;
    padding: 10px;
    margin: 0 auto;
    display: block;
    grid-column: 1 / span 2;
    background-color: #fff;
  }
  .is--logged-out .site-logo--mobile {
    padding-top: 20px;
  }
  .utility-bar {
    grid-row: 7;
    grid-column: 1 / span 2;
    background-color: #fff;
    padding: 0px;
  }
  .utility-bar__inner {
    grid-template-columns: 80% 20%;
    justify-items: center;
  }
  .utility-bar__inner a {
    color: #000;
  }
  .account-header {
    width: 100%;
    grid-column: 1;
    color: #000;
    background-color: #fff;
  }
  .header-cart {
    grid-column: 2;
    justify-self: end;
  }
  .account-header__account-greeting {
    display: none;
  }
  .account-header__header-i-guess {
    display: grid;
    grid-template-columns: auto auto 75%;
    align-items: center;
  }
  .account-header__item a {
    color: #fff;
  }
  .account-header__icon {
    grid-column: 1;
    grid-row: 1;
  }
  .account-header__icon .icon-person .icon-circle {
    stroke: #1d355d;
    stroke-width: 1;
  }
  .account-header__toggle {
    grid-column: 2;
    grid-row: 1;
    padding: 5px 10px 0 5px;
  }
  .account-header__title {
    grid-column: 3;
    text-align: left;
  }
  .header-cart__icon svg,
  .account-header__toggle svg {
    fill: #1d355d;
  }
  .header-cart__value {
    color: #fff;
  }
  .search {
    display: none;
  }
  .header-banner {
    display: none;
  }
  .title-wrap,
  .is--logged-in .title-wrap {
    padding: 0px;
  }
  .login-links {
    grid-column: 1;
    justify-self: start;
    padding-left: 20px;
    color: #58595B;
  }
  .login-links span {
    color: #58595B;
  }
  .header-search {
    padding-right: 10px;
    padding-left: 0;
    width: 100%;
    grid-column: 2;
    max-width: unset;
  }
}

.menu-wrap {
  border-bottom: 1px solid #acacac;
}

.site-nav {
  padding-top: 0;
  background-color: transparent;
}

.site-nav__inner {
  padding-top: 2em;
  background-color: rgba(242, 242, 242, 0.97);
}

.site-nav__item {
  border-bottom: none;
}

.site-nav__top {
  padding: 10px;
  color: #282828;
  font-size: 18px;
  font-weight: normal;
  background-color: transparent;
}

.site-nav__list {
  border-top: none;
}

.sub-nav {
  font-size: 18px;
}

.sub-nav li {
  border-bottom: none;
}

.sub-nav a {
  padding: 10px;
  background-color: transparent;
}

.sub-nav__back {
  font-weight: normal;
  border-bottom: none;
}

.sub-nav__highlight {
  color: #b5a468;
}

@media only screen and (min-width: 820px) {
  .site-nav {
    max-width: 1200px;
  }
  .site-nav__inner {
    padding-top: 0;
    background-color: transparent;
  }
  .site-nav__top {
    display: inline-flex;
    flex-direction: column;
    padding: 10px;
    /*&::after {
            content: attr(data-title);
            content: attr(data-title) / "";
            height: 0;
            visibility: hidden;
            overflow: hidden;
            user-select: none;
            pointer-events: none;
            font-style: italic;
            font-weight: 500;

            @media speech {
                display: none;
            }
        }*/
  }
  .site-nav__item:hover .site-nav__top {
    font-weight: 500;
    background-color: #f4efe6;
  }
  .site-nav__item--holiday .site-nav__top {
    color: #ffffff;
    background-color: #b1a068;
  }
  .site-nav__item--holiday:hover .site-nav__top {
    background-color: #f4efe6;
    font-weight: 500;
  }
  .sub-nav {
    font-size: 14px;
    background-color: white;
    border-radius: 0;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.16);
  }
  .sub-nav a {
    padding: 5px;
    text-transform: uppercase;
  }
  .sub-nav__inner {
    padding: 0;
    background-color: transparent;
  }
  .sub-nav__col {
    width: 175px;
  }
  .site-nav__item--wide .sub-nav__col {
    width: 100%;
  }
}

.menu-wrap .search {
  display: none;
}

.nav-toggles,
.nav-toggle {
  background-color: #fff;
  justify-self: center;
}

.burger {
  width: 30px;
  height: 23px;
}

.burger__middle {
  background: #1d355d;
  top: 10px;
}

.burger::before,
.burger::after {
  background: #1d355d;
}

@media only screen and (max-width: 620px) {
  .menu-wrap {
    border: 0px;
    display: grid;
    grid-template-columns: 1fr 4fr;
    padding: 10px 0;
  }
  .menu-wrap .search {
    display: flex;
  }
}

.site-footer {
  max-width: none;
  padding: 0;
}

.footer-stripe {
  padding: 30px 10px 10px;
  color: white;
  background-color: #22345a;
}

.footer-inner {
  max-width: 1000px;
  margin: 0 auto;
}

.footer-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.footer-social {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.footer-social__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin: 0 10px;
  text-align: center;
  background-color: white;
  border-radius: 100%;
  transition: background-color 0.3s ease-in-out;
}

.footer-social__link:hover {
  background-color: #c1b17e;
}

.social-icon {
  display: block;
  width: 24px;
  height: 24px;
}

.social-icon path {
  fill: #22345a;
}

.social-icon--instagram {
  width: 20px;
  height: 20px;
  transform: translate(1px, 1px);
}

.social-icon--instagram svg {
  width: 100%;
  height: 100%;
}

.social-icon--instagram path {
  fill: none;
}

.social-icon--twitter {
  width: 22px;
  height: 22px;
}

.footer-logo {
  max-width: 40px;
  margin-right: 30px;
}

.footer-logo__image {
  display: block;
  max-width: 100%;
  height: auto;
}

.footer-contact {
  margin: 15px 0;
  padding: 0 30px;
}

.footer-contact__info {
  text-transform: uppercase;
}

.footer-contact__link {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px;
  color: inherit;
  text-decoration: none;
}

.footer-contact__link:hover {
  text-decoration: none;
}

.footer-contact__link:hover .contact-icon path {
  fill: #c1b17e;
}

.footer-contact__link-text {
  display: block;
}

.contact-icon {
  display: block;
  width: 24px;
  height: auto;
  margin: 0 15px 0 0;
}

.contact-icon path {
  fill: white;
  transition: fill 0.3s ease-in-out;
}

@media only screen and (min-width: 640px) {
  .footer-contact {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-end;
    margin-bottom: 0;
    padding: 0;
  }
  .footer-contact__info {
    text-align: center;
  }
  .footer-contact__link {
    display: block;
    padding: 10px 15px;
  }
  .contact-icon {
    margin: 0 auto 5px;
  }
}

.footer-links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 15px;
  margin-bottom: 45px;
  padding-top: 20px;
  padding-right: 15px;
  padding-left: 15px;
}

.footer-links__section {
  flex: 1 1 45%;
  margin-bottom: 30px;
}

.footer-links__header {
  margin-bottom: 0.5em;
  font-weight: 500;
  text-transform: uppercase;
}

.footer-links__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-links__item {
  margin: 8px 0;
}

.footer-links__link {
  color: inherit;
  font-size: 16px;
}

@media only screen and (min-width: 640px) {
  .footer-links {
    justify-content: space-between;
    padding-right: 60px;
    padding-left: 60px;
    border-top: 2px solid white;
  }
  .footer-links__section {
    flex: unset;
    margin-bottom: 0;
  }
}

.footer-copyright {
  padding-bottom: 15px;
  text-align: center;
}

.footer-copyright__text {
  font-size: 1em;
  font-style: italic;
}

.footer-copyright__text a {
  color: inherit;
}

.pb-header-h2,
.catalogs__header {
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  text-transform: uppercase;
}

@media only screen and (min-width: 640px) {
  .pb-header-h2,
  .catalogs__header {
    font-size: 48px;
  }
}

.pb-home-link {
  color: #000;
  font-size: 1.25em;
  font-style: italic;
  font-weight: 500;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  transition: color 0.3s ease-in-out;
}

.pb-home-link::after {
  content: " >";
}

.pb-home-link:hover {
  color: #c1b17e;
}

.pb-home-link--button {
  padding: 0;
  line-height: 1;
  transform: translateY(0.05em);
  cursor: pointer;
}

.button--clear {
  display: inline;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: 1;
  background-color: transparent;
  border: none;
}

.page--default .main-content {
  max-width: none;
}

.bx-wrapper .bx-pager.bx-default-pager a {
  background-color: #707070bf;
}

.bx-wrapper .bx-pager.bx-default-pager a.active {
  background-color: #c1b17e;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover {
  background-color: #e2dbc3;
}

.slider--featured .bx-wrapper .bx-pager {
  bottom: -10px;
}

.bx-wrapper .bx-controls-direction a {
  width: 35px;
  height: 35px;
  background-image: url("/content/themes/premierbeauty/images/universal-nav-arrows.png");
  background-repeat: no-repeat;
  z-index: 900;
}

.bx-wrapper .bx-next,
.bx-wrapper .bx-next:hover {
  background-position: 100% 0;
}

.bx-wrapper .bx-prev,
.bx-wrapper .bx-prev:hover {
  background-position: 0 0;
}

.home-wrap .main-banner-wrap {
  margin: 0 auto;
}

.main-banner-wrap .bx-wrapper .bx-next {
  right: 20px;
  background-position: 100% 0;
}

.main-banner-wrap .bx-wrapper .bx-prev {
  left: 20px;
  background-position: 0 0;
}

.main-banner-wrap .bx-controls-direction {
  display: block;
}

.promo-bucket__text {
  font-size: 12px;
  font-weight: 700;
  /*font-family: $font_primary_bold;*/
  text-align: center;
  color: #22345a;
}

.pb-bl {
  text-align: center;
}

.promo-buckets {
  padding-top: 20px;
}

.promo-buckets__inner {
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  max-width: 1000px;
  margin: 0 auto;
}

.promo-buckets__divider {
  height: calc(100% - 70px);
  margin: 35px 0;
  border-right: 2px solid black;
}

.promo-bucket {
  max-width: 500px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
}

.promo-bucket__image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto 10px;
}

.promo-bucket__link {
  color: #22345a;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  text-transform: uppercase;
  transition: color 0.3s ease-in-out;
}

.promo-bucket__link::after {
  content: " >";
}

.promo-bucket__link:hover {
  color: #c1b17e;
  text-decoration: none;
}

.is--logged-out .promo-bucket__link--logged-in {
  display: none;
}

.is--logged-out .promo-bucket__link--logged-out {
  display: inline-block;
}

.is--logged-in .promo-bucket__link--logged-in {
  display: inline-block;
}

.is--logged-in .promo-bucket__link--logged-out {
  display: none;
}

.promo-bucket__inner img {
  height: 110px;
}

.pb-bl {
  margin-top: 10px;
}

.pb-ssr {
  margin: 0 auto;
  max-width: 1000px;
}

.pb-ssr img {
  width: 100%;
}

.logged-in-box-item {
  display: none;
}

.is--logged-in .activation-box-item {
  display: none;
}

.is--logged-in .logged-in-box-item {
  display: block;
}

@media only screen and (min-width: 820px) {
  .promo-bucket__inner {
    display: grid;
    grid-template-columns: 1fr 325px;
    grid-gap: 10px;
  }
  .promo-bucket__inner img {
    height: 150px;
  }
  .promo-bucket__text {
    font-size: 30px;
    text-align: left;
  }
  .pb-bl {
    margin-top: 20px;
    text-align: left;
  }
  .promo-bucket {
    text-align: left;
  }
  .promo-bucket__image {
    margin-bottom: 15px;
  }
  .promo-bucket__link {
    font-size: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .main-banner-wrap .bx-wrapper .bx-next,
  .main-banner-wrap .bx-wrapper .bx-prev {
    display: none;
  }
}

.matcher-buckets {
  background-color: #f8f8f8;
}

.matcher-buckets__inner {
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px 0;
}

.matcher-buckets__divider {
  height: 100%;
  background-color: #00000029;
}

.matcher-bucket {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
  align-items: center;
  padding: 10px 0;
  font-size: 18px;
  line-height: 1.35;
}

.matcher-bucket p {
  margin: 0 0 15px;
  padding: 0;
}

.matcher-bucket__image {
  display: none;
}

.matcher-bucket__text {
  order: 2;
  padding: 0 15px;
}

.matcher-bucket__icon {
  order: 1;
}

.matcher-bucket__description {
  display: none;
}

.matcher-bucket__title {
  margin: 0;
  padding: 0;
  font-size: 32px;
  font-weight: 300;
  text-transform: uppercase;
}

.matcher-bucket__graphic {
  display: block;
  width: 100%;
  height: auto;
  max-width: 175px;
  min-width: 75px;
  margin: 0 auto;
}

@media only screen and (min-width: 820px) {
  .matcher-buckets__inner {
    margin: 60px auto;
    padding-top: 0;
    padding-bottom: 0;
  }
  .matcher-bucket {
    grid-template-columns: auto 3fr 1fr;
    padding: 0;
  }
  .matcher-bucket__title {
    font-size: 42px;
  }
  .matcher-bucket__description {
    display: block;
  }
  .matcher-bucket__image {
    display: block;
    order: 1;
  }
  .matcher-bucket__text {
    order: 2;
    padding: 0 20px;
  }
  .matcher-bucket__icon {
    order: 3;
  }
  .matcher-bucket__graphic {
    max-width: 100%;
    min-width: unset;
  }
}

.home-media {
  max-width: 1000px;
  margin: 60px auto;
  font-size: 18px;
  text-align: center;
}

.home-media__link {
  display: inline-block;
  margin-top: 15px;
}

.home-instagram {
  padding: 30px 0;
  font-size: 18px;
  text-align: center;
  background-color: #f8f8f8;
}

.home-instagram__inner {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 30px;
}

.home-instagram__title {
  margin-bottom: 30px;
}

@media only screen and (min-width: 1000px) {
  .home-instagram__inner {
    padding: 0;
  }
}

.header--home,
.bar {
  margin: 0 0 15px;
  padding: 0;
  color: inherit;
  font-size: 32px;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
  background-color: transparent;
  overflow: hidden;
}

@media only screen and (min-width: 820px) {
  .header--home,
  .bar {
    font-size: 48px;
  }
}

.blog-scroller {
  max-width: 1200px;
  margin: 60px auto;
}

.home-article__title {
  padding: 0 10px;
  font-size: 1.5em;
  font-weight: 500;
  text-align: center;
}

.home-article__teaser {
  display: none;
}

.home-article__text .home-article__link {
  position: relative;
}

.home-article__text .home-article__link::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: transparent;
  pointer-events: none;
  transition: background-color 0.3s ease-in-out;
}

.home-article__text .home-article__link:hover::after {
  background-color: white;
}

.home-article__text .home-article__link:hover .home-article__link--read {
  opacity: 1;
}

.home-article__link--read {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 200;
  margin: auto;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  text-decoration: underline;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.home-article__link--read::after {
  content: " >";
}

.standard-banner {
  max-width: 1000px;
  margin: 60px auto;
}

.slider-outer {
  margin: 60px 0 0;
}

.slider-caption {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}

.catalogs {
  max-width: 1000px;
  margin: 60px auto;
  border-top: none;
}

.catalogs__header {
  margin-bottom: 0.5em;
  text-align: center;
  border-bottom: none;
}

.catalog__text {
  font-weight: 600;
}

.catalog__text::after {
  width: 24px;
  height: 24px;
  margin: 0 0 0 2px;
  background-image: url("/content/themes/premierbeauty/images/home/banner-arrows-blue.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  transform: rotate(180deg) translateY(-25%);
}

.map-section {
  max-width: 1000px;
  margin: 60px auto;
}

.map-txt-inner {
  border-top: 2px solid #b5a468;
  border-bottom: 2px solid #b5a468;
  border-left: 2px solid #b5a468;
  margin-top: 40px;
}

.txt {
  margin-top: 25px;
  margin-bottom: 25px;
}

.tx-mg {
  margin-left: 30px;
}

.att-con {
  margin-top: 20px;
}

.abp-map-title {
  font-size: 2.6em;
  font-weight: 400;
  margin-bottom: 10px;
  color: #282828;
}

.abp-map-tx {
  font-size: 1.2em;
  color: #282828;
}

.abp-lm {
  font-weight: 600;
  text-decoration: underline;
  color: #282828;
}

.abp-atten {
  font-weight: 700;
  /*font-family: $font_primary_bold;*/
  font-size: 1.6em;
  color: #000;
  margin: 0;
}

.abp-times {
  font-size: 1.2em;
  color: #282828;
}

@media (max-width: 1199.98px) {
  .map-img {
    max-width: 100%;
  }
}

.filters__header {
  font-size: 0.9em;
  background-color: #22345a;
}

.pagination__link--active, .pagination__link--active:hover {
  color: white;
  background: #22345a;
}

.product-list-inner {
  margin-bottom: 30px;
}

.product-list {
  margin-top: 15px;
}

.price__label--highlight {
  background-color: #a2925b;
}

.button--notify {
  color: #fff;
  text-decoration: none;
}

.button--notify, .button--notify:hover {
  color: #fff;
}

.deal-sku__link {
  color: #4e748b;
}

/*@import "./styles/education";*/
.account-nav__list {
  border-top-color: #22345a;
}

.account-info {
  padding-bottom: 30px;
}

/*@media only screen and (min-width: 720px) {
  .login-section {    
    width: 50%;
  }
}*/
.fader__title {
  background: #22345a;
}

.instashop-summary {
  font-family: "futura-pt", "Helvetica", "Arial", sans-serif;
  font-size: 18px;
  text-align: center;
}

.instashop-summary h1 {
  font-size: 36px;
  font-weight: 300;
}

@media screen and (min-width: 820px) {
  .instashop-summary h1 {
    font-size: 48px;
  }
}

.beyond-beauty .beyond-beauty__h2 {
  margin: 0 0 20px;
  color: #A9A19B;
  font-size: 35px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
}

.beyond-beauty .beyond-beauty__h3 {
  margin: 0 0 10px;
  color: #1d355d;
  font-size: 28px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
}

.beyond-beauty .beyond-beauty__events,
.beyond-beauty .beyond-beauty__p {
  text-align: left;
  font-size: 25px;
}

.beyond-beauty .beyond-beauty__events {
  margin: 10px 0;
}

.beyond-beauty .beyond-beauty-qualifiers__note.notes {
  font-size: 17px;
  text-align: left;
}

.beyond-beauty li {
  text-align: left;
}

.beyond-beauty .beyond-beauty__p {
  font-weight: 600;
  margin-bottom: 0px;
}

.beyond-beauty .beyond-beauty__ss {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}

.beyond-beauty .beyond-beauty__ss img {
  width: 600px;
}

.beyond-beauty .bb-box__img {
  display: flex;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.beyond-beauty ul {
  margin: 0 !important;
}

@media only screen and (max-width: 680px) {
  .beyond-beauty .beyond-beauty__p {
    padding-bottom: 20px;
  }
}

.beyond-beauty__h2 .beyond-beauty__note {
  display: block;
  margin-top: 5px;
  color: #4c4c4c;
  font-size: 18px;
  font-weight: normal;
}

@media only screen and (min-width: 840px) {
  .beyond-beauty__h2 .beyond-beauty__note {
    font-size: 16px;
  }
}

.beyond-beauty__button {
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  background-color: #988F87;
  border-radius: 4px;
}

@media only screen and (min-width: 840px) {
  .beyond-beauty__button {
    font-size: 18px;
  }
}

.beyond-beauty__button.top {
  background-color: #293847;
  padding: 10px 130px;
}

@media only screen and (max-width: 840px) {
  .beyond-beauty__button.top {
    padding: 10px 105px;
  }
}

.beyond-beauty-header .beyond-beauty-header__title {
  margin-bottom: 0;
}

.beyond-beauty-header__links {
  text-align: center;
  margin-top: 60px;
}

.beyond-beauty-header__link {
  margin: 15px 0;
}

.beyond-beauty-summary {
  max-width: 850px;
  margin: 60px auto;
  padding: 0 20px;
  text-align: center;
}

.beyond-beauty-summary .beyond-beauty-summary__text {
  padding: 0;
  margin: 0;
  font-size: 18px;
}

.beyond-beauty-summary .beyond-beauty-summary__lfg {
  padding: 0;
  margin: 0;
  color: #1d355d;
  font-size: 24px;
  font-weight: bold;
}

@media only screen and (min-width: 840px) {
  .beyond-beauty-summary .beyond-beauty-summary__text {
    font-size: 20px;
    color: #293847;
  }
  .beyond-beauty-summary .beyond-beauty-summary__lfg {
    font-size: 36px;
  }
}

@media only screen and (min-width: 1000px) {
  .beyond-beauty-summary {
    padding-right: 0;
    padding-left: 0;
  }
}

.beyond-beauty-qualifiers {
  padding: 0 20px;
  font-size: 18px;
  text-align: center;
}

.beyond-beauty-qualifiers .beyond-beauty-qualifiers__list {
  max-width: 600px;
  margin: 30px auto !important;
  font-size: 18px;
  text-align: left;
}

.beyond-beauty-qualifiers__note {
  display: block;
  color: #78879f;
  font-size: 16px;
  font-style: italic;
}

@media only screen and (min-width: 840px) {
  .beyond-beauty-qualifiers {
    font-size: 20px;
    color: #293847;
  }
  .beyond-beauty-qualifiers .beyond-beauty-qualifiers__list {
    font-size: 20px;
    color: #293847;
  }
  .beyond-beauty-qualifiers__note {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1000px) {
  .beyond-beauty-qualifiers {
    padding-right: 0;
    padding-left: 0;
  }
}

.beyond-beauty-tier {
  position: relative;
  margin: 60px 0;
  padding: 40px 30px 20px;
  text-align: left;
  text-transform: uppercase;
  border: 2px solid #B6BABE;
}

.beyond-beauty-tier .beyond-beauty-tier__name {
  position: absolute;
  top: 0;
  left: 15px;
  margin-top: -3px;
  background-color: #fff;
  transform: translateY(-50%);
}

.beyond-beauty-tier .beyond-beauty-tier__description {
  margin: 0;
  padding: 0;
  font-weight: bold;
}

.beyond-beauty-tier .beyond-beauty-tier__benefits {
  margin: 0;
}

.beyond-beauty-tier li {
  margin: 0;
  line-height: 1.25;
}

@media only screen and (min-width: 840px) {
  .beyond-beauty-tier {
    margin: 60px 90px;
    padding: 40px 75px 20px;
  }
  .beyond-beauty-tier .beyond-beauty-tier__name {
    left: 75px;
  }
}

@media only screen and (min-width: 840px) {
  .beyond-beauty-tier {
    margin: 60px 90px;
    padding: 40px 60px 20px;
  }
}

.beyond-beauty-action {
  margin: 60px 20px;
  text-align: center;
}

@media only screen and (min-width: 1000px) {
  .beyond-beauty-action {
    margin: 60px 0;
  }
}

.pb-about {
  font-size: 18px;
}

.landing .pb-about-title,
.landing .pb-ourTeam-title {
  margin: 0 0 20px;
  padding: 0;
  color: #202020;
  font-family: "futura-pt", "Helvetica", "Arial", sans-serif;
  font-size: 40px;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
}

.pb-about-link {
  color: #000;
  font-size: 1.25em;
  font-style: italic;
  /*font-family: $font_primary_bold;*/
  font-weight: bold;
  text-decoration: underline;
  transition: color 0.2s ease-in-out;
}

.pb-about-link::after {
  content: "\00A0>";
}

.pb-about-link:hover {
  color: #b5a268;
}

.pb-about-header {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  padding: 15px;
}

.pb-about-header__map {
  margin: 0 auto;
  order: 1;
}

.pb-about-header__text {
  order: 2;
}

.pb-about-feature__text {
  text-align: center;
}

.pb-about-feature__text p {
  font-weight: 600;
}

@media only screen and (max-width: 819.98px) {
  .pb-ourTeam-feature {
    padding: 15px;
  }
  .pb-ourTeam__position {
    text-align: center;
  }
  .pb-ourTeam__text {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 820px) {
  .pb-about-header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    column-gap: 30px;
    margin: 60px 0;
    padding: 0 15px;
    font-size: 24px;
  }
  .pb-about-header__map {
    order: 2;
  }
  .pb-about-header__text {
    order: 1;
  }
}

@media only screen and (min-width: 1000px) {
  .pb-about-header {
    padding: 0;
  }
}

.pb-about-media {
  margin: 30px 0;
}

@media only screen and (min-width: 820px) {
  .pb-about-media {
    margin: 60px 0;
  }
}

.pb-about-history__image {
  position: relative;
  z-index: 100;
  display: block;
  width: 100%;
  height: auto;
}

.pb-about-history__text {
  padding: 0 15px;
}

@media only screen and (min-width: 820px) {
  .landing .pb-about-title {
    text-align: left;
  }
  .pb-about-history {
    position: relative;
    padding: 0;
    font-size: 24px;
  }
  .pb-about-history__text {
    position: absolute;
    top: 10%;
    right: 0;
    left: 0;
    z-index: 200;
    max-width: 700px;
    margin: auto;
    padding: 0;
    font-size: 18px;
  }
  .pb-about-history__text p:last-child {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1000px) {
  .pb-about-history,
  .pb-about-why {
    margin-left: calc(-100vw / 2 + 1020px / 2);
    margin-right: calc(-100vw / 2 + 1020px / 2);
  }
  .pb-about-history__text {
    font-size: 24px;
  }
}

.pb-about-why {
  padding: 10px 30px 60px;
  font-size: 16px;
  background-color: #fcfcfc;
}

.pb-ourTeam__inner {
  max-width: 1000px;
  margin: 0 auto;
}

.pb-about-why__inner {
  max-width: 1000px;
  margin: 0 auto 50px;
}

.pb-about-team {
  margin-bottom: 50px;
}

.txt-img {
  margin: 0 auto;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1200px) {
  .pb-ourTitle-feature {
    padding: 15px 0;
  }
  .pb-ourTitle__title {
    margin-top: 5px;
    margin-bottom: 5px;
    color: #4d6f8a;
    /*font-family: $font_primary_bold;*/
    font-style: italic;
    font-weight: bold;
  }
  .pb-ourTitle__image {
    max-width: 75px;
    margin: 0 auto;
  }
}

.pb-about-feature {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  padding: 15px 0;
}

.pb-about-feature__icon {
  height: 143px;
  width: 143px;
  margin: 0 auto;
}

.pb-about-feature__icon.icon-family {
  background-image: url("/media/pagecontent/articles/editor/premier beauty/about-us/about-us-family-owned-blue.svg");
}

.pb-about-feature__icon.icon-family:hover {
  background-image: url("/media/pagecontent/articles/editor/premier beauty/about-us/about-us-family-owned-gold.svg");
}

.pb-about-feature__icon.icon-arrival {
  background-image: url("/media/pagecontent/articles/editor/premier beauty/about-us/about-us-arrival-blue.svg");
}

.pb-about-feature__icon.icon-arrival:hover {
  background-image: url("/media/pagecontent/articles/editor/premier beauty/about-us/about-us-arrival-gold.svg");
}

.pb-about-feature__icon.icon-guarantee {
  background-image: url("/media/pagecontent/articles/editor/premier beauty/about-us/about-us-ship-blue.svg");
}

.pb-about-feature__icon.icon-guarantee:hover {
  background-image: url("/media/pagecontent/articles/editor/premier beauty/about-us/about-us-ship-gold.svg");
}

.pb-about-feature__icon.icon-local {
  background-image: url("/media/pagecontent/articles/editor/premier beauty/about-us/about-us-local-blue.svg");
}

.pb-about-feature__icon.icon-local:hover {
  background-image: url("/media/pagecontent/articles/editor/premier beauty/about-us/about-us-local-gold.svg");
}

.pb-about-feature__icon.icon-beyond {
  background-image: url("/media/pagecontent/articles/editor/premier beauty/about-us/about-us-bb-blue.svg");
}

.pb-about-feature__icon.icon-beyond:hover {
  background-image: url("/media/pagecontent/articles/editor/premier beauty/about-us/about-us-bb-gold.svg");
}

.landing .pb-about-feature__title {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #4d6f8a;
  font-family: serif;
  font-style: italic;
  font-weight: bold;
}

.landing .pb-ourTeam__title {
  margin-top: 15px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.pb-ourTeam__icon img {
  margin: 0 auto;
  filter: grayscale(100%);
  transition: all 0.2s ease-in-out;
}

.pb-ourTeam__icon img:hover {
  filter: grayscale(0%);
}

@media only screen and (min-width: 820px) {
  .pb-about-why__inner {
    margin-bottom: 0;
  }
  .pb-about-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 6px;
  }
  .pb-ourTeam-grid__twoImg {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
  }
  .pb-ourTeam-grid__threeImg {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
  }
  .pb-ourTeam-grid__fourImg {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 30px;
  }
  .feature-first {
    justify-self: end;
  }
  .feature-second {
    justify-self: start;
  }
  .pb-ourTeam__inner {
    margin-top: 50px;
  }
  .pb-ourTeam-feature {
    display: block;
    padding: 0;
  }
  .pg-ourTeam-feature__icon {
    width: 100%;
    max-width: 90px;
    margin: 0 auto;
  }
  .pb-ourTeam__position {
    text-align: center;
  }
  .landing .pb-about-feature__title {
    margin-top: 15px;
  }
}

.landing {
  padding-top: 0px !important;
}

.boxes-container.mg-boxes {
  margin: 0 auto 97.4062px !important;
}

.boxes-container.mg-bottom {
  margin-bottom: 97.4062px !important;
}

.imgBox-container.mg-top {
  margin-top: 0 !important;
}

.button.shop-btn {
  color: #fff;
  width: 100%;
  font-size: 36px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  background-color: #c1b17e;
  border-radius: 10px;
  padding: 10px 0;
  margin-top: 30px;
}

.button.shop-btn:hover {
  background-color: #22345a;
}

.titleM {
  font-size: 2.2em !important;
}

.text-top-padding {
  padding: 0px 15px !important;
}

.h1-sm-title {
  font-size: 2.1em !important;
}

.p-size {
  font-size: 1.5em !important;
}

.p-lineHight {
  line-height: 28px !important;
}

.h1-exl-title {
  font-size: 2em !important;
}

.text-lr-padding {
  padding: 70px 0px !important;
}

.text-all-padding {
  padding: 70px 15px !important;
}

.remove-top-padding {
  padding: 0px 15px !important;
}

.titleL {
  font-size: 2.3em !important;
}

.brand-stories .img-container {
  padding-bottom: 20px;
  margin: 0 auto 60px;
}

.brand-stories .boxes-container {
  margin: 60px auto;
}

.brand-stories .boxes-container .description-box {
  padding-bottom: 20px;
}

.brand-stories .boxes-container .description-box .p-container h1 {
  font-weight: 300;
}

.brand-stories .boxes-container .description-box .p-container p {
  font-weight: 400;
  font-size: 22px;
  color: #000;
}

.brand-stories .boxes-container .description-box .p-container .section-padding {
  padding: 70px 15px;
}

.brand-stories .imgBox-container {
  margin: 0;
  margin-top: 97.4062px !important;
  margin-bottom: 97.4062px !important;
}

.brand-stories .imgBox-container .imgs-container__three {
  padding: 20px 0;
}

@media only screen and (min-width: 1000px) {
  .landing__body {
    padding: 0 0px 15px !important;
  }
  .img-container {
    margin-right: calc(-100vw / 2 + 1020px / 2) !important;
    margin-left: calc(-100vw / 2 + 1020px / 2) !important;
  }
  .vidoe-container {
    margin-right: calc(-100vw / 2 + 1020px / 2) !important;
    margin-left: calc(-100vw / 2 + 1020px / 2) !important;
  }
}

@media only screen and (max-width: 1000px) {
  .boxes-container .description-box .img-box {
    margin-bottom: 70px !important;
  }
  .boxes-container .description-box .p-container .section-padding {
    padding: 0px 0px !important;
  }
}

.pb-glp {
  margin-top: 30px;
}

.hiw-title {
  font-weight: bold;
  /*font-family: $font_primary_bold;*/
  font-size: 2.5em !important;
  text-align: center;
  color: #22345a;
  padding: 30px 0 !important;
}

.hiw-text {
  font-size: 1.5em;
  color: #202020;
}

.bxs-btn {
  text-align: center;
  background: #22345a;
  width: 100%;
  height: 80px;
}

.bxs-text {
  font-weight: 600;
  border-radius: 0px !important;
  background-color: #22345a !important;
}

.pb-oosCon {
  border-top: 2px solid #dfdfdf;
}

.oos-title {
  font-weight: bold;
  /*font-family: $font_primary_bold;*/
  font-size: 2.5em !important;
  text-align: center;
  color: #22345a;
  padding: 30px 0;
}

.oos-img {
  padding: 30px;
}

.pb-bxs {
  margin: 50px 0;
}

.pb-oos {
  background-color: #22345a;
}

.oos-imgText {
  color: #fff !important;
  font-size: 1.5em;
  color: #202020;
  padding: 0 30px 30px !important;
}

.q-container {
  position: relative;
  top: 130px;
}

.q-title {
  font-weight: bold;
  /*font-family: $font_primary_bold;*/
  font-size: 2.5em !important;
  text-align: center;
  color: #fff;
}

.q-contact {
  padding-top: 30px;
  color: #fff;
  font-size: 1.5em;
  text-align: center;
}

@media (min-width: 1000px) {
  .pb-questions {
    margin-right: calc(-100vw / 2 + 1020px / 2);
    margin-left: calc(-100vw / 2 + 1020px / 2);
    height: 350px;
    background-color: #576d87;
    text-align: center;
    margin-top: 80px;
  }
}

@media (max-width: 999px) {
  .pb-questions {
    height: 350px;
    background-color: #576d87;
    text-align: center;
  }
}

@media (max-width: 819px) {
  .pb-glp {
    margin-top: 15px !important;
  }
  .hiw-title {
    padding: 15px 0 !important;
  }
  .hiw-text {
    font-size: 1.2em;
    color: #202020;
    margin: 0 20px !important;
  }
  .bxstwo {
    margin-top: 30px;
  }
  .shopN {
    text-align: center;
  }
  .button.shop-btn {
    width: 90%;
  }
  .oos-title {
    font-size: 1.9em !important;
  }
  .oos-imgText {
    font-size: 1.3em;
  }
}

.rewards-container p {
  font-size: 30px;
  text-align: center;
}

.rewards-container > div {
  margin-top: 60px;
}

.rewards-container > div:first-of-type {
  margin-top: 0px;
}

@media (max-width: 575.98px) {
  .rewards-container p {
    font-size: 15px;
  }
}

.recent-orders__popup {
  margin-top: 150px !important;
}

.dialog-content--quick-view {
  margin-top: 180px !important;
}

.samples__toggle {
  background: none;
  background-color: #4d6e88;
  color: #fff;
}

.samples__toggle--svg-container {
  background-color: #1d355d;
}

.myaccount-nav__link {
  background-color: #22345a;
}

.myaccount-nav__link--selected, .myaccount-nav__link:hover {
  background-color: #c1b17e;
}

.dashboard-buckets img {
  max-width: 100%;
  height: auto;
}

.promo-header {
  background-color: #d8e0ed;
}

.product-list__header-link {
  color: #22345a;
}

.promo-assistance .g-6,
.promo-assistance .g-6:first-of-type {
  border-color: #d8e0ed;
}

@media only screen and (min-width: 680.02px) {
  .promo-resources__block {
    padding-left: 10px;
  }
}

.store-popup {
  z-index: 9001;
}
