﻿.landing {
  padding-top: 0px !important;  
}

.boxes-container.mg-boxes {
  margin: 0 auto 97.4062px !important;
}

.boxes-container.mg-bottom {
  margin-bottom: 97.4062px !important;
}

.imgBox-container.mg-top {
  margin-top: 0 !important;
}

.button.shop-btn {
  color: #fff;
  width: 100%;
  font-size: 36px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  background-color: #c1b17e;
  border-radius: 10px;
  padding: 10px 0;
  margin-top: 30px;
}

.button.shop-btn:hover {
  background-color: #22345a;
}

//  Brazil
.titleM {
  font-size: 2.2em !important;
}

//  ColorMe, Eleven
.text-top-padding {
  padding: 0px 15px !important;
}

//  ColorMe
.h1-sm-title {
  font-size: 2.1em !important;
}

.p-size {
  font-size: 1.5em !important;
}

.p-lineHight {
  line-height: 28px !important;
}

//  K18
.h1-exl-title {
  font-size: 2em !important;
}

.text-lr-padding {
  padding: 70px 0px !important;
}

//  K18, Milbon, Unite
.text-all-padding {
  padding: 70px 15px !important;
}

// Murphy
.remove-top-padding {
  padding: 0px 15px !important;
}

.titleL {
  font-size: 2.3em !important;
}

.brand-stories {
  .img-container {
    padding-bottom: 20px;
    margin: 0 auto 60px;
  }
  .boxes-container {
    margin: 60px auto;
    .description-box {
      padding-bottom: 20px;
      .p-container {
        h1 {
          font-weight: 300;
        }
        p {
          font-weight: 400;
          font-size: 22px;
          color: #000;
        }
        .section-padding {
          padding: 70px 15px;
        }
      }
    }
  }
  .imgBox-container {
    margin: 0;
    margin-top: 97.4062px !important;
    margin-bottom: 97.4062px !important;
    .imgs-container__three {
      padding: 20px 0;
    }
  }
}

@media only screen and (min-width: 1000px) {
  .landing__body {
    padding: 0 0px 15px !important;
  }
  .img-container {
    margin-right: calc(-100vw / 2 + 1020px / 2) !important;
    margin-left: calc(-100vw / 2 + 1020px / 2) !important;
  }
  .vidoe-container {
    margin-right: calc(-100vw / 2 + 1020px / 2) !important;
    margin-left: calc(-100vw / 2 + 1020px / 2) !important;
  }
}

@media only screen and (max-width: 1000px) {
  .boxes-container {
    .description-box {
      .img-box {
        margin-bottom: 70px !important;
      }
      .p-container {
        .section-padding {
          padding: 0px 0px !important;
        }
      }
    }
  }
}
