﻿.rewards-container p {
    font-size: 30px;
    text-align: center;
}

.rewards-container > div {
    margin-top: 60px;
}

.rewards-container > div:first-of-type {
    margin-top: 0px;
}

@media (max-width: 575.98px) {
    .rewards-container p {
        font-size: 15px;
    }
}
