﻿// -- shared
.pb-about {
  font-size: 18px;
}

.landing .pb-about-title,
.landing .pb-ourTeam-title {
    margin: 0 0 20px;
    padding: 0;
    color: #202020;
    font-family: $font_primary;
    font-size: 40px;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;    
}

.pb-about-link {
    color: #000;
    font-size: 1.25em;
    font-style: italic;
    /*font-family: $font_primary_bold;*/
    font-weight: bold;
    text-decoration: underline;
    transition: color 0.2s ease-in-out;

    &::after {
        content: "\00A0>";
    }

    &:hover {
        color: #b5a268;
    }
}

// -- header
.pb-about-header {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  padding: 15px;
}

.pb-about-header__map {
    margin: 0 auto;
    order: 1;
}

.pb-about-header__text {
  order: 2;
}

.pb-about-feature__text {
  text-align: center;

  p {
    font-weight: 600;
  }
}

@media only screen and (max-width: 819.98px) {
  .pb-ourTeam-feature {
    padding: 15px;
  }

  .pb-ourTeam__position {
    text-align: center;
  }

  .pb-ourTeam__text {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 820px) {
  .pb-about-header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    column-gap: 30px;
    margin: 60px 0;
    padding: 0 15px;
    font-size: 24px;
  }

  .pb-about-header__map {
    order: 2;
  }

  .pb-about-header__text {
    order: 1;
  }
}

@media only screen and (min-width: 1000px) {
  .pb-about-header {
    padding: 0;
  }
}

// -- media
.pb-about-media {
  margin: 30px 0;
}

@media only screen and (min-width: 820px) {
  .pb-about-media {
    margin: 60px 0;
  }
}

// -- history
.pb-about-history__image {
    position: relative;
    z-index: 100;
    display: block;
    width: 100%;
    height: auto;
}

.pb-about-history__text {
  padding: 0 15px;
}

@media only screen and (min-width: 820px) {
    .landing .pb-about-title {
        text-align: left;
    }

    .pb-about-history {
        position: relative;
        padding: 0;
        font-size: 24px;
    }

  .pb-about-history__text {
    position: absolute;
    top: 10%;
    right: 0;
    left: 0;
    z-index: 200;
    max-width: 700px;
    margin: auto;
    padding: 0;
    font-size: 18px;

    p:last-child {
      margin-bottom: 0;
    }
  }
}

@media only screen and (min-width: 1000px) {
  .pb-about-history,
  .pb-about-why {
    margin-left: calc(-100vw / 2 + 1020px / 2);
    margin-right: calc(-100vw / 2 + 1020px / 2);
  }

  .pb-about-history__text {
    font-size: 24px;
  }
}

// -- why premier
.pb-about-why {
  padding: 10px 30px 60px;
  font-size: 16px;
  background-color: #fcfcfc;  
}

.pb-ourTeam__inner {
  max-width: 1000px;
  margin: 0 auto;
}

.pb-about-why__inner {
  max-width: 1000px;
  margin: 0 auto 50px;
}

.pb-about-team {
    margin-bottom: 50px;
}

.txt-img {
    margin: 0 auto;
    margin-bottom: 20px;
}

@media only screen and (min-width: 1200px) {  
  .pb-ourTitle-feature {
    padding: 15px 0;    
  }

    .pb-ourTitle__title {
        margin-top: 5px;
        margin-bottom: 5px;
        color: #4d6f8a;
        /*font-family: $font_primary_bold;*/
        font-style: italic;
        font-weight: bold;
    }

    .pb-ourTitle__image {
        max-width: 75px;
        margin: 0 auto;
    }
}

.pb-about-feature {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    padding: 15px 0;
}

.pb-about-feature__icon {
    height: 143px;
    width: 143px;
    margin: 0 auto;
}

.pb-about-feature__icon.icon-family {
    background-image: url('/media/pagecontent/articles/editor/premier beauty/about-us/about-us-family-owned-blue.svg');

    &:hover {
        background-image: url('/media/pagecontent/articles/editor/premier beauty/about-us/about-us-family-owned-gold.svg')
    }
}

.pb-about-feature__icon.icon-arrival {
    background-image: url('/media/pagecontent/articles/editor/premier beauty/about-us/about-us-arrival-blue.svg'); 
    
    &:hover {
        background-image: url('/media/pagecontent/articles/editor/premier beauty/about-us/about-us-arrival-gold.svg')
    }
}

.pb-about-feature__icon.icon-guarantee {
    background-image: url('/media/pagecontent/articles/editor/premier beauty/about-us/about-us-ship-blue.svg');

    &:hover {
        background-image: url('/media/pagecontent/articles/editor/premier beauty/about-us/about-us-ship-gold.svg')
    }
}

.pb-about-feature__icon.icon-local {
    background-image: url('/media/pagecontent/articles/editor/premier beauty/about-us/about-us-local-blue.svg');

    &:hover {
        background-image: url('/media/pagecontent/articles/editor/premier beauty/about-us/about-us-local-gold.svg')
    }
}

.pb-about-feature__icon.icon-beyond {
    background-image: url('/media/pagecontent/articles/editor/premier beauty/about-us/about-us-bb-blue.svg');

    &:hover {
        background-image: url('/media/pagecontent/articles/editor/premier beauty/about-us/about-us-bb-gold.svg')
    }
}

.landing .pb-about-feature__title {
    margin-top: 5px;
    margin-bottom: 5px;
    color: #4d6f8a;
    font-family: serif;
    font-style: italic;
    font-weight: bold;
}

.landing .pb-ourTeam__title {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

.pb-ourTeam__icon img {
    margin: 0 auto;
    filter: grayscale(100%);
    transition: all 0.2s ease-in-out;

    &:hover {
        filter: grayscale(0%);
    }
}

@media only screen and (min-width: 820px) {
    .pb-about-why__inner {
        margin-bottom: 0;
    }

    .pb-about-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 6px;
    }

  .pb-ourTeam-grid__twoImg {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
  }

  .pb-ourTeam-grid__threeImg {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
  }

  .pb-ourTeam-grid__fourImg {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 30px;
  }

  .feature-first {
    justify-self: end;
  }

    .feature-second {
        justify-self: start;
    }

    .pb-ourTeam__inner {        
        margin-top: 50px;
    }

  .pb-ourTeam-feature {
    display: block;
    padding: 0;    
  }

    .pg-ourTeam-feature__icon {
        width: 100%;
        max-width: 90px;
        margin: 0 auto;
    }

    .pb-ourTeam__position {
        text-align: center;
    }

    .landing .pb-about-feature__title {
        margin-top: 15px;
    }
}
