// - colors
$primary_blue: hsl(220.7, 45.2%, 24.3%); // #22345a;
$dark_blue: #1d355d; 
$bright_blue: hsl(0, 92, 160); // #005ca0
$highlight_gold: hsl(45.7, 35.1%, 62.5%); // #c1b17e;
$light_blue: hsl(217.1, 36.8%, 88.8%); // d8e0ed;
$text_dark: hsl(0, 0, 15.7%); // #282828;
$light_gray: hsl(0, 0, 97.3%); // #f8f8f8
$dark_gray: hsl(0, 0, 43.9%); // #707070

$color_primary: $primary_blue;
$color_primary_dark: $dark_blue;
$color_highlight: $highlight_gold;
$color_secondary: $light_blue;
$color_white: hsl(0, 0, 100%); // #fff
$color_light_gray: $light_gray;
$color_dark_gray: $dark_gray;
$color_link: $bright_blue;
$color_link_highlight: #b5a468;

$box_shadow: 3px 3px 8px rgba(0,0,0,.16);
$font_primary: "futura-pt", "Helvetica", "Arial", sans-serif;

// - fonts
/*@import "./styles/fonts";*/

body {
    font-family: $font_primary;
}

img {
    max-width: 100%;
    height: auto;
}

h1, h1 a {
    color: $color_primary;
}

a {
    color: $color_highlight;
}

// Full width pencil banner
.main-content {
    max-width: none;
}

.main-content__inner {
    > div {
        max-width: 1000px;
        margin: 0 auto;
    }
}

.has--pencil .pencil,
.has--pencil .pencil-multi {
    max-width: none;
}

.pencil-banner {
    max-width: none;
}

//Allow wider widths on home / explore deals pages
.page--default,
.page--explore-deals-default {
    .main-content__inner > div {
        max-width: none;
    }
}

.landing__header {
    display: none;
}

@import "./styles/buttons";
@import "./styles/header";
@import "./styles/nav";
@import "./styles/footer";
@import "./styles/home";
@import "./styles/product-list";
/*@import "./styles/education";*/
@import "./styles/account";
@import "./styles/login";
@import "./styles/articles";
@import "./styles/instashop";
@import "./styles/beyond-beauty";
@import "./styles/about-us";
@import "./styles/brand-stories";
@import "./styles/guarantee";
@import "./styles/rewards";
@import "./styles/orders";
@import "./styles/samples-toggle";
@import "./styles/dashboard";
@import "./styles/explore-deals";
@import "./styles/in-store";